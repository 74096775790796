import React from "react";
import { FluentResource } from "@fluent/bundle";
import { LocalizationProvider, Localized } from "@fluent/react";

import { track } from "@util/analytics";
import { newReactLocalization } from "@util/l10n";

import styles from "./styles.module.scss";

const RESOURCES = {
  en: new FluentResource(`oops-not-found = Oops! Page not found...
not-found-return-home = Return home.`),
  es: new FluentResource(`oops-not-found = ¡Ups! Página no encontrada...
not-found-return-home = Volver a casa.`),
};

export default function NotFound() {
  const l10n = newReactLocalization(RESOURCES);

  return (
    <section className={styles["not-found-page"]}>
      <LocalizationProvider l10n={l10n}>
        <a
          className="text:large color:primary"
          href="/"
          onClick={() => {
            track.tap("404 - Return Home Button");
          }}
        >
          <Localized id="not-found-return-home">Return home.</Localized>
        </a>
      </LocalizationProvider>
    </section>
  );
}
